<section class="content-section bg-primary text-white text-center">
  <div class="container">
    <div class="row sponsors justify-content-center" *ngFor="let sponsorCategory of sponsorCategories">
      <h2>{{ sponsorCategory.naam }}</h2>
      <div [ngClass]="sponsorCategory.klasse" *ngFor="let sponsor of sponsorCategory.sponsors">
        <a href="{{sponsor.link}}" target="_blank">
          <div class="sponsor">
            <img src="{{sponsor.imageUrl}}" alt="Image" class="img-fluid fade-on-hover">
            <div *ngIf="sponsor.pitch" class="text">{{sponsor.pitch}}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
