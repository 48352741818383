import { Component, OnInit } from '@angular/core';
import {Sponsor} from "./sponsor";
import {SponsorCategory} from './sponsor-category';

@Component({
  selector: 'sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  sponsorCategories: SponsorCategory[] = [];

  constructor() {}

  ngOnInit() {
    this.sponsorCategories.push(
      new SponsorCategory("DIAMOND SPONSORS",
        [
          new Sponsor("https://www.buildyourhome.be/nl/aannemer/frames/BE0463018612",
            "assets/sponsors/aframes.png", "A-Frames Bocholt, " +
            "Nieuwbouw en renovatie van uw buitenschrijnwerk zonder zorgen." +
            "Tel. 089 481656 voor een afspraak en een woordje uitleg over " +
            "onze ramen en deuren uit eigen productie. "),
          new Sponsor("https://bt-gofflo.be/", "assets/sponsors/btgofflo.png", "Gofflo Group maakt het je gemakkelijk bij bouw- en renovatiewerken. Vertel ons je plan en wij nemen je zorgen weg. Wij zijn een totaalpartner waarop je kan rekenen tijdens alle stappen van het project. Of het nu gaat om grondwerken, ruwbouw, interieurafwerking, gyproc of schrijnwerk, met één aanspreekpunt binnen Gofflo Group ben je zeker van een vlotte, persoonlijke communicatie en een hecht team van bouwexperten.\n" +
            "Neem vandaag nog contact met ons op en vertel ons over je droomproject: info@bt-gofflo.be."),
          new Sponsor("https://www.dry-plan.be/", "assets/sponsors/dryplan.png", "Vochtspecialist DryPlan is jouw partner voor een verregaande vochtbestrijding. Vanuit onze centrale uitvalsbasis in Mechelen staan we paraat voor gezinnen en bedrijven in heel Vlaanderen. We gaan geen uitdaging uit de weg en maken komaf met opstijgend vocht, condensatie en schimmels. Ons team schakelt razendsnel en reikt steeds een gepaste oplossing aan. "),
          new Sponsor("https://www.schalleymichel.be/", "assets/sponsors/schalleymichel.png", "Wij doen vooral kleine en grote renovatieprojecten van A tot Z\n" +
            "•\tRuwbouw- en rioleringswerken\n" +
            "•\tDakwerken \n" +
            "•\tBadkamerrenovaties  \n" +
            "•\tMaatkasten \n" +
            "Wij staan in voor een zorgeloze verbouwing.\n ")
        ], "col-sm-12 col-md-12 col-lg-6 diamond"));

    this.sponsorCategories.push(
      new SponsorCategory("GOLD SPONSORS",
        [
          new Sponsor("https://goliath-gs.be/", "assets/sponsors/Goliath.png", null),
          new Sponsor("https://www.vgttechnics.be/", "assets/sponsors/vgttechnics.png", null),
          new Sponsor("https://www.vccbv.nl/", "assets/sponsors/vcc.png", null),
          new Sponsor("https://www.dezwaluwhoeve.com/", "assets/sponsors/zwaluwhoevelogouni.png", null),
          new Sponsor("https://www.vastgoedfransen.be/", "assets/sponsors/fransen.jpg", null),
          new Sponsor("http://www.broekmansassurfin.be/", "assets/sponsors/broekmans.png", null),
          new Sponsor("https://www.mooi-interieur.be/", "assets/sponsors/mooiinterieur.png", null)
        ], "col-sm-12 col-md-6 col-lg-4"));

    this.sponsorCategories.push(
      new SponsorCategory("SILVER SPONSORS",
        [
          new Sponsor("https://spie.be", "assets/sponsors/SPIE.png", null),
          new Sponsor("https://driesennv.be/", "assets/sponsors/driesen.png", null),
          new Sponsor("https://gebrola.com/", "assets/sponsors/gebrola.png", null),
          new Sponsor("https://www.companyweb.be/nl/0544960450/gejo-interieur", "assets/sponsors/gejo.png", null),
          new Sponsor("https://doubleuservice.eu/", "assets/sponsors/doubleU.png", null),
          new Sponsor("https://aregoverzekeringen.be/", "assets/sponsors/arego.png", null)
        ], "col-6 col-lg-2"));

    this.sponsorCategories.push(
      new SponsorCategory("SUPPORT SPONSORS",
        [
          new Sponsor("https://maten-architecten.be/", "assets/sponsors/archipal.png", null),
          new Sponsor("https://ondernemersnetwerk.be/business/BE0766769954/eclips-heating/contact", "assets/sponsors/eclipseheating.png", null),
          new Sponsor("https://www.i2accountants.be/", "assets/sponsors/i2accountants.jpg", null),
          new Sponsor("https://lecot.be/nl-be/", "assets/sponsors/lecot.png", null),
          new Sponsor("https://kwanten.com/", "assets/sponsors/kwanten.png", null),
          new Sponsor("https://www.rdwtuinen.be/", "assets/sponsors/Breex.jpg", null),
          new Sponsor("https://www.studiopelt.be/", "assets/sponsors/studio.png", null),
          new Sponsor("https://vdgpaint.be/", "assets/sponsors/vdg.png", null)
        ], "col-4 col-lg-2 support"));
  }

}
